.ant-form-item-explain {
    text-align: start;
}
.mainClass {
    overflow: auto !important;
}
.signup-input-btn button {
    background-color: #609DA1 !important;
    color: white !important;
    font-size: 14px;
}
  
.signup-input-btn button:hover {
    box-shadow: 0 20px 27px rgb(0 0 0 / 20%);
}