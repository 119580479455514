.extra_shadow {
    box-shadow: 0 20px 27px rgb(0 0 0 / 50%) !important;
}

.card_container {
    @apply w-3/4 flex pl-52 gap-10;
}

.one_third_card {
    @apply w-1/3 flex flex-col justify-center items-center;
}

.card_content {
    @apply font-roboto text-center;
}

.audit_header {
    @apply px-52 text-2xl font-roboto font-black;
}
.note {
    @apply px-52
}
.editable_card {
    @apply text-base border border-black hover:border-[#609DA1] hover:shadow-lg hover:scale-125 ease-in duration-300;
}