.shareDataCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px !important;
  height: 385px !important;

}

.shareDataCardActive {
  box-shadow: 0 20px 27px rgba(96, 157, 161, 0.3) !important;
  border: 2px solid #609da1;
}
.vertical-line {
  width: 1px;
  background-color: #ccc; /* Change the color as needed */
  margin: 10px 20px; /* Adjust the margin as needed */
}
.shareDataUpload .ant-upload {
  background: none;
  border: none;
  cursor: default;
  padding: 0 !important;
}
.shareDataUpload:hover {
  background: none;
  border: none;
  cursor: default;
  padding: 0 !important;
  transform: scale(1.20);
  box-shadow: 0 20px 27px rgb(0 0 0 / 10%) !important;
  transition: 0.5s ease;
}
.success-border {
  border: 2px solid green !important;
}
.shareDataUploadBtn {
  box-shadow: 0 20px 27px rgb(0 0 0 / 30%);
}

.shareDataUploadBtn:hover {
  transform: scale(1.20);
  box-shadow: 0 20px 27px rgb(0 0 0 / 10%) !important;
  transition: 0.5s ease;
  cursor: pointer;
}
