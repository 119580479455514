.ant-form-item-explain {
    text-align: start;
}
.verify-input-btn button {
    background-color: #609DA1 !important;
    color: white !important;
    font-size: 14px;
}
  
.verify-input-btn button:hover {
    box-shadow: 0 20px 27px rgb(0 0 0 / 20%);
}

.verify-signup {
    color: #609DA1 !important;
}

.verify-signup:hover {
    color: #69b1ff !important;
}