.loadingLogoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
    /* padding: 50px; */
}

.loadingLogo {
	/* width: 150px; */
	animation: rotate 5s linear infinite;
	border-radius: 100%;
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
