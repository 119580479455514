label.ant-form-item-required::before {
    content: "" !important;
  }
  .divider {
    margin: 0 !important;
  }
  .ant-form-item-explain {
    text-align: start;
  }
  
  .login-input-btn button {
    background-color: #609DA1 !important;
    color: white !important;
    font-size: 14px;
  }
  
  .login-input-btn button:hover {
    box-shadow: 0 20px 27px rgb(0 0 0 / 20%);
  }
  @media (max-width: 1280px) {
    .login-text {
      font-size: 30px !important;
    }
    .divider {
      margin: 0 !important;
    }
    .login-input .ant-row {
      margin: auto 0 !important;
    }
  }
  @media (min-width: 1450px) {
    .login-div {
      row-gap: 4rem !important;
    }
  }
  
  .brandlogo{
    max-width: 130% !important;
  }