.demoAppTable .ant-table-thead >tr>th{
    color: #ffffff;
    background-color: #609DA1;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    /* border: 1px black solid; */
}
.demoAppTable .ant-table-tbody > tr > td{
    color: black !important;
    background-color: white;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}

.extraTable .ant-table-tbody > tr > td, 
.extraTable .ant-table-thead > tr > th {
    padding: 10px 2px;
    text-align: center;
}